module.exports = {
  siteTitle: 'Everysport.tv - Sport på TV idag!', // Site title.
  siteLogo: '/logos/etv/etv2.png', // Logo used for SEO and manifest.
  siteUrl: 'https://www.everysport.tv', // Domain of your website without pathPrefix.
  siteDescription: 'Everysport.tv guidar dig livesända matcher på TV och stream. Vi vill erbjuda ett snabbt sätt att hitta rätt tv-kanal eller streamingsajt för att uppleva sport live. I möjligaste mån vill vi djuplänka direkt till rätt match/sändning och till dom bästa oddsen på livesport. Tipsa oss gärna om sporter, kanaler och matcher vi kan ha missat.',
  siteFBAppID: 'xx', // FB Application ID for using app insights
  siteGATrackingID: 'yyy', // Tracking code ID for google analytics.
  copyright: 'Copyright © 2017. Eversyport TV', // Copyright string for the footer of the website and RSS feed.
  facebookUrl: 'https://www.facebook.com/Everysporttv',
  twitterUrl: 'https://twitter.com/everysporttv',
};
