import moment from "moment"
import "moment/locale/sv"
import React from "react"
import {
  getNextDays,
  getDetails,
  FULL_DAY_FORMAT,
} from "../../utils/dateHelper"
import EventListItem from "../EventListItem/eventListItem"
import "./event-groups.scss"

const _ = require("lodash")

const ALL = "ALL"

export default class EventGroups extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayOver: !props.isWidget,
    }
  }

  render() {
    const { eventEdges, selectedDay, numberOfDays, isWidget } = this.props
    //console.log("event edges", eventEdges)
    const eventGroups = []
    const dayFrom = selectedDay ? selectedDay.replace("/", "") : selectedDay
    let daysToShowEvents

    if (ALL === numberOfDays) {
      daysToShowEvents = _.sortBy(
        _.uniqWith(
          _.map(eventEdges, ({ node }) =>
            moment(node.date).locale("sv").format(FULL_DAY_FORMAT)
          ),
          _.isEqual
        ),
        dateStr => moment(dateStr).locale("sv")
      )
    } else {
      daysToShowEvents = getNextDays(dayFrom, numberOfDays)
    }

    daysToShowEvents.forEach(day => {
      const nowMinus2 = moment().locale("sv").subtract(2, "hour")
      // filter events for the day
      const edgesOfTheDay = _.filter(eventEdges, ({ node }) =>
        moment(node.date).isBetween(
          moment(day).add(5, "hour"),
          moment(day).add(29, "hour")
        )
      )

      let filteredEdges = edgesOfTheDay
      let expandCollapseDiv

      if (moment().locale("sv").format(FULL_DAY_FORMAT) === day) {
        const filterOutOverEdges = _.filter(filteredEdges, ({ node }) =>
          nowMinus2.isBefore(moment(node.date).locale("sv"))
        )

        if (!this.state.displayOver) {
          filteredEdges = filterOutOverEdges
        }

        if (edgesOfTheDay.length > filterOutOverEdges.length) {
          expandCollapseDiv = (
            <button
              className={`${
                this.state.displayOver
                  ? "expand-collapse-button expand-collapse-button--expanded"
                  : "expand-collapse-button"
              }`}
              onClick={() =>
                this.setState({ displayOver: !this.state.displayOver })
              }
            >
              {this.state.displayOver ? (
                <span className="expand-collapse-button-content">
                  Dölj avslutade sporthändelser
                </span>
              ) : (
                <span className="expand-collapse-button-content">
                  Visa avslutade sporthändelser
                </span>
              )}
            </button>
          )
        }
      }

      const sortedEdges = _.sortBy(filteredEdges, ({ node }) =>
        moment(node.date).locale("sv")
      )

      const eventsListItems = _.map(sortedEdges, ({ node }) => (
        <EventListItem isWidget={isWidget} node={node} key={node.id} />
      ))
      const details = getDetails(day)

      eventGroups.push(
        <div
          key={details.key}
          id={details.key}
          className="event-group-container"
          style={{
            display:
              eventsListItems.length === 0 && !expandCollapseDiv ? "none" : "",
          }}
        >
          <div className="event-group-date">{`${details.day}, ${details.date} ${details.month}`}</div>
          {expandCollapseDiv}
          {eventsListItems}
        </div>
      )
    })

    return <div key={"event-group"}>{eventGroups}</div>
  }
}

export { ALL }
