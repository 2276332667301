import React from 'react';
import './sport-icon.scss';

const SportIcon = (props) => {
  const { backgroundColor, sportId } = props;
  const bgColor = backgroundColor || 'silver';
  return (
    <div className="sport-icon-container" style={{ backgroundColor: `${bgColor}` }}>
      <div className="sport-icon" style={{ backgroundImage: `url('/logos/sport/${sportId}.png')` }} />
    </div>
  );
};

export default SportIcon;
