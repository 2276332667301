import moment from 'moment';
import 'moment/locale/sv';

const FULL_DAY_FORMAT = 'YYYYMMDD';
const YESTERDAY = 'YESTERDAY';
const TODAY = 'TODAY';
const TOMORROW = 'TOMORROW';

function getDates() {
  const days = [];
  const dayItr = moment().locale('sv').subtract(1, 'day');

  for (let i = 0; i < 7; i += 1) {
    const date = dayItr.format('D');
    let day = dayItr.format('dddd');
    const month = dayItr.format('MMMM');
    let key = dayItr.format(FULL_DAY_FORMAT);
    if (i === 0) {
      day = 'Igår';
      key = YESTERDAY;
    } else if (i === 1) {
      day = 'Idag';
      key = TODAY;
    } else if (i === 2) {
      day = 'Imorgon';
    }
    days.push(
      {
        date,
        key,
        full: dayItr.format(FULL_DAY_FORMAT),
        day,
        month,
      },
    );
    dayItr.add(1, 'day');
  }
  return days;
}

function getNextDays(day, numberOfDays) {
  const dayFrom = day || moment().locale('sv').format(FULL_DAY_FORMAT);
  const days = [dayFrom];
  const dayItr = moment(dayFrom).locale('sv');
  const noOfDays = numberOfDays || 3;

  for (let i = 1; i < noOfDays; i += 1) {
    days.push(dayItr.add(1, 'day').format(FULL_DAY_FORMAT));
  }

  return days;
}

function getDetails(dayFull) {
  const day = moment(dayFull, FULL_DAY_FORMAT).locale('sv');
  const yesterday = moment().locale('sv').subtract(1, 'day').format(FULL_DAY_FORMAT);
  const today = moment().locale('sv').format(FULL_DAY_FORMAT);
  const tomorrow = moment().locale('sv').add(1, 'day').format(FULL_DAY_FORMAT);
  let key = dayFull;
  let dayDef = day.format('dddd');

  if (day.isSame(yesterday, 'day')) {
    dayDef = 'Igår';
    key = YESTERDAY;
  } else if (day.isSame(today, 'day')) {
    dayDef = 'Idag';
    key = TODAY;
  } else if (day.isSame(tomorrow, 'day')) {
    dayDef = 'Imorgon';
    key = TOMORROW;
  }

  const details = {
    full: dayFull,
    date: day.format('D'),
    month: day.format('MMMM'),
    day: dayDef,
    key,
  };
  return details;
}

export { getDates, YESTERDAY, TODAY, TOMORROW, FULL_DAY_FORMAT, getNextDays, getDetails };
