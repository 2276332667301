import React from "react"
import EventGroups from "../../components/EventGroups/eventGroups"
import "./index.scss"
import config from "../../../data/SiteConfig"
import { graphql } from "gatsby"

const _ = require("lodash")

const WidgetPage = props => {
  const queryString = props.location.search
    ? props.location.search.substring(1)
    : ""
  const widgetParams = {}
  const vars = queryString.split("&")
  const headerHeight = 55
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split("=")
    widgetParams[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
  }

  console.log(queryString, "Korhan", widgetParams)
  const widgetStyle = widgetParams.h
    ? { maxHeight: widgetParams.h.replace("px", "") - headerHeight }
    : {}
  const widgetContainerStyle = widgetParams.w
    ? { maxWidth: widgetParams.w }
    : {}
  let filteredEdges = props.data.events.edges

  if (widgetParams.sport) {
    filteredEdges = _.filter(filteredEdges, ({ node }) =>
      _.includes(widgetParams.sport.split(","), node.sport.contentful_id)
    )
  }
  if (widgetParams.league) {
    filteredEdges = _.filter(filteredEdges, ({ node }) =>
      _.includes(widgetParams.league.split(","), node.league.contentful_id)
    )
  }
  if (widgetParams.channel) {
    let channelEdges = []
    const reqChannels = widgetParams.channel.split(",")
    for (let i = 0; i < reqChannels.length; i += 1) {
      channelEdges = channelEdges.concat(
        _.filter(filteredEdges, ({ node }) =>
          _.includes(
            _.map(node.channels, nodeChannel => nodeChannel.contentful_id),
            reqChannels[i]
          )
        )
      )
    }
    filteredEdges = channelEdges
  }
  return (
    <div className="widget-page-container" style={widgetContainerStyle}>
      <div className="widget-header">
        <a href={config.siteUrl} target="_blank" rel="noreferrer">
          <img
            className="widget-header-logo"
            src="/logos/etv/etv2.png"
            alt="Everysport TV"
          />
        </a>
      </div>
      <div className="widget" style={widgetStyle}>
        <EventGroups isWidget eventEdges={filteredEdges} />
      </div>
    </div>
  )
}
export default WidgetPage

export const pageQuery = graphql`
  query WidgetQuery {
    allLinks: allContentfulLink(
      limit: 50
      filter: { node_locale: { eq: "sv-SE" } }
    ) {
      edges {
        node {
          label
          url
          section
          order
        }
      }
    }
    events: allContentfulEvent(filter: { node_locale: { eq: "sv-SE" } }) {
      edges {
        node {
          id
          uri
          date
          location
          betradarId
          everysportId
          bookmakerUrl
#          bookmaker {
#            name
#            externalUrl
#            logo {
#              file {
#                url
#              }
#            }
#            mobileLogo {
#              file {
#                url
#              }
#            }
#          }
          league {
            id
            name
            uri
            contentful_id
          }
          sport {
            backgroundColor
            id
            contentful_id
          }
          homeTeam {
            name
          }
          awayTeam {
            name
          }
          detail {
            detail
          }
          channelLinks {
            channel
            externalUrl
          }
          channels {
            id
            contentful_id
            name
            externalUrl
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
