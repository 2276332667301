const vsSports = ['TENNIS', 'BADMINTON', 'FIGHTING'];

function getEventName(homeTeam, awayTeam, detail, sport) {
  if (homeTeam && awayTeam) {
    const vsStr = vsSports.indexOf(sport.code) >= 0 ? ' vs ' : ' - ';
    return homeTeam.name + vsStr + awayTeam.name;
  }
  return detail ? detail.detail : '-';
}

export { getEventName, vsSports };
