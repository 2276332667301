import moment from "moment"
import "moment/locale/sv"
import React, { Component } from "react"
import { Link } from "gatsby"
import config from "../../../data/SiteConfig"
import "./event-list-item.scss"
import SportIcon from "../SportIcon/sportIcon"
import Channel from "../Channel/channel"
import { getEventName } from "../../utils/eventHelper"
// import Odds, { ODDS_FULL, ODDS_MOBILE_FULL } from "../Odds/odds"

export default class EventListItem extends Component {
  render() {
    const { node, isWidget } = this.props
    const renderEventTitle = () => {
      const eventName = getEventName(
        node.homeTeam,
        node.awayTeam,
        node.detail,
        node.sport
      )
      return <div className="event-name">{eventName}</div>
    }
    const eventDate = moment(node.date).locale("sv")
    const now = moment().locale("sv")
    const nowMinus2 = moment().locale("sv").subtract(2, "hour")
    const listItemStyle = nowMinus2.isAfter(eventDate) ? { opacity: 0.5 } : {}

    let eventHour
    let eventHourClass = "time-container-time"
    if (now.isAfter(eventDate) && nowMinus2.isBefore(eventDate)) {
      eventHour = "LIVE"
      eventHourClass = "time-container-live"
    } else {
      eventHour = eventDate.format("HH:mm")
    }

    // const displayOdds =
    //   node.bookmaker && node.betradarId ? {} : { display: "none" }

    const channels =
      node.channels &&
      node.channels.map((channel, index) => (
        <Channel
          key={`${index}_${channel.id}`}
          channel={channel}
          links={node.channelLinks}
        />
      ))

    return (
      <div
        key={node.id}
        className="event-list-item-container"
        style={listItemStyle}
      >
        <SportIcon
          backgroundColor={node.sport.backgroundColor}
          sportId={node.sport.contentful_id}
          style={listItemStyle}
        />
        <div key={node.id} className="event-list-item">
          <div className="time-container">
            <div className={eventHourClass}>{eventHour}</div>
          </div>
          <div key={node.id} className="event-list-item-content">
            <div className="event-info-container">
              {isWidget ? (
                <a
                  href={`${config.siteUrl}${node.uri}`}
                  target="_blank"
                  rel="noreferrer"
                  className="event-info-title"
                >
                  {renderEventTitle()}
                  <div className="event-league">
                    {node.league ? node.league.name : ""}
                  </div>
                </a>
              ) : (
                <Link to={`${node.uri}`} className="event-info-title">
                  {renderEventTitle()}
                  <div className="event-league">
                    {node.league ? node.league.name : ""}
                  </div>
                </Link>
              )}
              {/*<div className="desktop-odds-container" style={displayOdds}>*/}
              {/*  <Odds*/}
              {/*    bookmaker={node.bookmaker}*/}
              {/*    bookmakerUrl={node.bookmakerUrl}*/}
              {/*    betradarId={node.betradarId}*/}
              {/*    everysportId={node.everysportId}*/}
              {/*    displayType={ODDS_FULL}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>

            <div className="event-list-item-bottom-row">
              {/*<div className="mobile-odds-container">*/}
              {/*  <Odds*/}
              {/*    bookmaker={node.bookmaker}*/}
              {/*    bookmakerUrl={node.bookmakerUrl}*/}
              {/*    betradarId={node.betradarId}*/}
              {/*    everysportId={node.everysportId}*/}
              {/*    displayType={ODDS_MOBILE_FULL}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="channel-row-container">{channels}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
