import React from "react"
import "./channel.scss"

const _ = require("lodash")

const Channel = ({ channel, links, disableLinks }) => {
  const link = _.find(links, ["channel", channel.id])
  const channelLink =
    link && link.externalUrl && link.externalUrl.length > 5
      ? link.externalUrl
      : channel.externalUrl

  const x = disableLinks ? (
    <div className="channel-container">
      <img
        className="channel-image"
        src={`${channel.logo.file.url}?w=75`}
        alt={channel.name}
      />
    </div>
  ) : (
    <a href={channelLink} target="_blank" rel="noreferrer nofollow noopener">
      <div className="channel-container">
        <img
          className="channel-image"
          src={`${channel.logo.file.url}?w=75`}
          alt={channel.name}
        />
      </div>
    </a>
  )

  return x
}

export default Channel
